import { UserProvider } from "@prisma/client";
import { addMinutes } from "date-fns";
import { nanoid } from "nanoid";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { assertNotNil } from "~/lib/utils";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import {
  enforceSamlAuthentication,
  selectCompanyForSamlAuthentication,
} from "~/services/auth/saml/enforceSamlAuthentication";

export const createAuthenticationToken = async (ctx: AppContext, params: { user: RequiredAuthenticatedUser }) => {
  const companyId = assertNotNil(params.user.companyId);

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    select: selectCompanyForSamlAuthentication,
  });

  enforceSamlAuthentication({
    company,
    profileData: { provider: UserProvider.EMAIL_AND_TOKEN, email: params.user.email },
  });

  // There can only be one valid token per email at any point in time
  await ctx.prisma.userAuthenticationToken.updateMany({ where: { email: params.user.email }, data: { valid: false } });

  const token = nanoid();
  await ctx.prisma.userAuthenticationToken.create({
    data: {
      email: params.user.email.toLowerCase(),
      token,
      valid: true,
      expiresAt: addMinutes(new Date(), config.auth.tokenExpirationInMinutes),
    },
  });

  return token;
};
