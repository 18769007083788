import classNames from "classnames";
import React from "react";
import { Icon } from "~/components/ui/core/Icon";
import { type IconName } from "~/components/ui/Icons";

type Props = React.PropsWithChildren<{
  icon?: IconName;
  size?: keyof typeof Sizes;
  className?: string;
}>;

const Sizes = {
  "lg": {
    container: "space-x-2",
    text: "text-lg",
    textColor: "text-gray-800",
    icon: "w-4 h-4",
  },
  "md": {
    container: "space-x-1.5",
    text: "text-md",
    textColor: "text-gray-700",
    icon: "w-4 h-4",
  },
  "sm": {
    container: "space-x-1.5",
    text: "text-sm",
    textColor: "text-gray-700",
    icon: "w-4 h-4",
  },
  "xs": {
    container: "space-x-1.5",
    text: "text-xs",
    textColor: "text-gray-600",
    icon: "w-3 h-3",
  },
  "2xs": {
    container: "space-x-1.5",
    text: "text-2xs",
    textColor: "text-gray-600",
    icon: "w-3 h-3",
  },
};

export const LegacyText: React.FC<Props> = (props) => {
  const { size = "sm", className, icon, children } = props;
  const textColor = Sizes[size].textColor;
  if (icon) {
    return (
      <div
        className={classNames(Sizes[size].container, className, {
          "flex items-center": true,
        })}
      >
        {icon && (
          <div className={Sizes[size].icon}>
            <Icon
              className={classNames(Sizes[size].icon, {
                "text-gray-400": true,
              })}
              name={icon}
            />
          </div>
        )}
        <span className={classNames(Sizes[size].text, textColor, "flex flex-wrap items-center space-x-1")}>
          {children}
        </span>
      </div>
    );
  }

  return <div className={classNames(className, Sizes[size].text, textColor, "space-x-1")}>{children}</div>;
};
