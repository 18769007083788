import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object, string } from "yup";
import { api } from "~/lib/api";
import { SamlAuthenticationEnforcedError } from "~/lib/errors/samlAuthenticationEnforcedError";
import { trackLoginLinkEmailed } from "~/lib/external/segment/server/events";
import { sendLoginLinkEmail } from "~/lib/external/sendinblue/templates";
import { createMutation } from "~/lib/reactQuery";
import { createAuthenticationToken } from "~/services/auth/authentication-token/createAuthenticationToken";
import { fetchRequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const RequestAuthTokenClientSchema = object({
  email: string().email().required(),
});

type RequestAuthTokenResponse = {
  samlAuthenticationUrl?: string;
};

const handler: NextApiHandler<RequestAuthTokenResponse> = async (req, res) => {
  const input = RequestAuthTokenClientSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const user = await req.prisma.user.findUnique({
    where: { email: input.email.toLowerCase(), invitationNeedsReview: false },
    select: { id: true },
  });

  // To avoid leaking user information, if the email is not found in our database we just fail silently and not send any token
  if (!!user) {
    try {
      const authenticatedUser = await fetchRequiredAuthenticatedUser(req, { userId: user.id });
      const token = await createAuthenticationToken(req, { user: authenticatedUser });

      await sendLoginLinkEmail(req, {
        ...input,
        token,
        locale: authenticatedUser.locale,
      });

      await trackLoginLinkEmailed(req, authenticatedUser);
    } catch (error) {
      if (error instanceof SamlAuthenticationEnforcedError) {
        return res.status(HttpStatus.OK).json({
          samlAuthenticationUrl: error.samlAuthenticationUrl,
        });
      }

      throw error;
    }
  }

  res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: { optional: true },
});

export const useRequestTokenMutation = createMutation<typeof handler, typeof RequestAuthTokenClientSchema>({
  path: "/api/auth/token/request",
  schema: RequestAuthTokenClientSchema,
});
