import { Dialog, Transition } from "@headlessui/react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import React, { Fragment, useEffect } from "react";
import { Icon } from "~/components/ui/core/Icon";
import { PortalRootProvider } from "~/hooks/usePortalRoot";
import { trackModalOpened } from "~/lib/external/segment/web/events";

type Props = React.PropsWithChildren<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  initialFocus?: React.MutableRefObject<null>;
}>;

export const LegacyModal: React.FC<Props> = ({ initialFocus, title, isOpen, onClose, className, children }) => {
  useEffect(() => {
    if (isOpen) {
      void trackModalOpened({ name: title });
    }
  }, [isOpen, title]);

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        open
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          onClose();
        }}
        initialFocus={initialFocus}
      >
        <div className="flex min-h-screen items-center justify-center" id="modal">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-75 transition duration-1000 hover:opacity-70" />

          <Transition.Child
            as={Fragment}
            enter="transition duration-150 ease-out"
            enterFrom="transform scale-95 -translate-y-24 opacity-0"
            enterTo="transform scale-100 translate-y-0 opacity-100"
            leave="transition duration-150 ease-out"
            leaveFrom="transform scale-100 translate-y-0 opacity-100"
            leaveTo="transform scale-95 translate-y-24 opacity-0"
          >
            <div
              className={classNames(
                className,
                "relative z-10 mx-auto animate-scale overflow-auto rounded-lg bg-white p-3 shadow-2xl"
              )}
              style={{ maxHeight: "98vh" }}
            >
              <Dialog.Title>
                <div className="relative -m-3 -mb-0 flex h-16 items-center overflow-hidden bg-primary-300 px-4">
                  <Typography
                    variant="h1"
                    className="mr-16 space-x-2 font-title text-3xl font-semibold leading-none tracking-wide text-gray-800"
                  >
                    {title}
                  </Typography>
                  <button
                    className="absolute top-5 right-4 transform text-gray-800 transition hover:scale-125 hover:text-gray-700 focus:outline-none"
                    tabIndex={-1}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <Icon name="plain-close" size="lg" />
                  </button>
                </div>
              </Dialog.Title>
              <PortalRootProvider selector="#modal">{children}</PortalRootProvider>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
