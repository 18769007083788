import { Alert, Stack, TextField } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import { object, string } from "yup";
import { Button } from "~/components/ui/core/Button";
import { Modal } from "~/components/ui/core/Modal";
import { useForm } from "~/hooks/useForm";
import { makeSamlRedirectionUrl } from "~/services/auth/saml/samlUrls";

type FormValues = {
  email: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SamlLoginModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const router = useRouter();

  const form = useForm<FormValues>({
    initialValues: { email: "" },
    validateOnMount: true,
    validationSchema: object({
      email: string().required(),
    }),
    onSubmit: (values) => {
      void router.push(
        makeSamlRedirectionUrl({ email: values.email, redirect: router.query.redirect as string | undefined })
      );
    },
  });

  return (
    <Modal className="w-[550px]" isOpen={isOpen} title="Authenticate with SAML SSO" onClose={onClose}>
      <Stack className="w-full" spacing={4}>
        <Alert severity="info">
          Please provide your professional email address.
          <br />
          You'll be redirected to your company's authentication provider.
        </Alert>

        <Stack component="form" className="w-full" direction="row" spacing={2} onSubmit={form.handleSubmit}>
          <TextField
            className="flex-1"
            placeholder="Email address"
            value={form.values.email}
            onChange={(event) => form.setValues({ email: event.target.value })}
          />
          <Button type="submit" variant="contained" disabled={form.isSubmitting || !form.isValid}>
            Proceed
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
