import { Check, Email } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { Button } from "~/components/ui/core/Button";
import { LegacyFormControl } from "~/components/ui/core/LegacyFormControl";
import { LegacyModal } from "~/components/ui/core/LegacyModal";
import { LegacyText } from "~/components/ui/core/LegacyText";
import { TextInput } from "~/components/ui/core/TextInput";
import { useForm } from "~/hooks/useForm";
import { ApiValidationError } from "~/lib/errors/apiValidationError";
import { RequestAuthTokenClientSchema, useRequestTokenMutation } from "~/pages/api/auth/token/request";

type FormValues = {
  email: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const LoginLinkModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [loginLinkIsSent, setLoginLinkIsSent] = useState(false);
  const initialFocusRef = useRef(null);

  const requestToken = useRequestTokenMutation();

  const form = useForm<FormValues>({
    initialValues: { email: "" },
    validateOnMount: true,
    validationSchema: RequestAuthTokenClientSchema,
    onSubmit: async (values, { setErrors }) => {
      if (loginLinkIsSent) {
        return;
      }

      try {
        const response = await requestToken.mutateAsync({ email: values.email });
        if (response.samlAuthenticationUrl) {
          window.location.href = response.samlAuthenticationUrl;
          return;
        }

        setLoginLinkIsSent(true);
      } catch (error) {
        if (error instanceof ApiValidationError) {
          setErrors(error.fields);
        }
      }
    },
  });

  return (
    <LegacyModal
      className="w-[600px]"
      isOpen={isOpen}
      title={loginLinkIsSent ? "Check your inbox!" : "Receive a Login Link by email"}
      onClose={() => {
        setLoginLinkIsSent(false);
        form.resetForm();
        onClose();
      }}
      initialFocus={initialFocusRef}
    >
      {!loginLinkIsSent && (
        <div>
          <form className="mt-5 flex flex-row gap-4" onSubmit={form.handleSubmit}>
            <div className="flex-grow">
              <LegacyFormControl label="Your email address" errors={form.errors.email} touched={!!form.touched.email}>
                <TextInput
                  value={form.values.email}
                  onChange={(event) => {
                    void form.setFieldValue("email", event.target.value);
                  }}
                  disabled={form.isSubmitting || loginLinkIsSent}
                  type="email"
                  placeholder="contact@email.com"
                  ref={initialFocusRef}
                />
              </LegacyFormControl>
            </div>
            <Button
              className="self-end"
              type="submit"
              size="large"
              startIcon={loginLinkIsSent ? <Check /> : <Email />}
              variant="contained"
              disabled={form.isSubmitting || !form.isValid || loginLinkIsSent}
            >
              {loginLinkIsSent ? "Email sent!" : "Send email"}
            </Button>
          </form>

          <LegacyText icon="information" className="mt-5 w-full rounded-lg bg-yellow-100 p-2">
            We will send a Login Link directly to your email address, which allows you to login without a password or a
            Google / Microsoft account.
          </LegacyText>
        </div>
      )}
      {loginLinkIsSent && (
        <LegacyText className="mt-3 rounded-lg bg-yellow-100 p-3">
          <div>
            If <strong>{form.values.email}</strong> is an account on Figures, you'll receive an email with a Login Link
            in a few instants. <br />
            Don't forget to check your spam folder!
          </div>
        </LegacyText>
      )}
    </LegacyModal>
  );
};
