import { type Prisma, UserProvider } from "@prisma/client";
import { SamlAuthenticationEnforcedError } from "~/lib/errors/samlAuthenticationEnforcedError";
import { type ProfileData } from "~/services/auth/profileData";

export const selectCompanyForSamlAuthentication = {
  samlConfiguration: {
    select: {
      enforceSamlAuthentication: true,
    },
  },
} satisfies Prisma.CompanySelect;

export const enforceSamlAuthentication = (params: {
  company: Prisma.CompanyGetPayload<{ select: typeof selectCompanyForSamlAuthentication }>;
  profileData: Pick<ProfileData, "provider" | "email">;
}) => {
  if (params.profileData.provider === UserProvider.SAML) return;

  if (!params.company.samlConfiguration?.enforceSamlAuthentication) return;

  throw new SamlAuthenticationEnforcedError(params.profileData.email);
};
